<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Dispatch Importer</b-card-title>
			<b-card-sub-title>Manages the import of single dispatch</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="handleSubmit" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- Dispatch Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="Dispatch Form"
										description="Please select a valid json file for dispatch format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm"
											:state="Boolean(file)" placeholder="Choose a JSON file"></b-form-file>
									</b-form-group>
									<div>
										Download sample template
										<a :href="templateUrl" download="Dispatch Importer.json">here.</a>
									</div>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-show="!importOngoing && loadedTextFromFile.length > 0" title="Content Preview"
							sub-title="Below is the overview of the content of the dispatch form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData">Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Component
import dispatchImporterTemplate from '@/assets/files/DispatchImporter.json';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { ImportUtil } from '@/utils/importUtil';

// API
import assetsDAO from '@/database/assets';
import dataImporterApi from '@/api/dataImporterApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'dispatch-importer',
	components: { Loading, },
	data() {
		return {
			file: null,
			jsonData: {},

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],
			loadedTextFromFile: '',

			dispatch: null,

			companiesObj: {},
			storageLocationsObj: {},
			connectionsObj: {},
			transportationsObj: {},
			assetTypesObj: {},
			assetsObj: {},

			templateUrl: 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dispatchImporterTemplate, null, '\t')),

			currUserId: this.$store.getters.loggedUser.id,
			isLoading: false,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
	},
	mounted() {		
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				// Load initial data
				this.companiesObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.companies, ...this.$store.getters.connectedCompanies }, 'name');
				this.storageLocationsObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations }, 'name');
				this.usersObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.users, ...this.$store.getters.connectedUsers }, 'id');
				this.connectionsObj = ImportUtil.getConnectionsObj({ ...this.$store.getters.connections });
				this.transportationsObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.transportations }, 'plateNo');
				this.assetTypesObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.assetTypes }, 'name');

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.loadedTextFromFile = '';
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];
			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					// load the content of the file on a string variable
					this.loadedTextFromFile = e.target.result;
					this.jsonData = JSON.parse(this.loadedTextFromFile);
					this.jsonData = ImportUtil.trimWhiteSpaces(this.jsonData);
				};
				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;

			if (!file) {
				this.$toaster.warning('Please select a valid dispatch form to proceed.');
				isValid = false;
			} else if (file.type != 'application/json') {
				this.$toaster.error('Invalid File Type: Please import a valid dispatch form in JSON format.');
				isValid = false;
			}

			if (!isValid) {
				this.loadedTextFromFile = '';
			}
			return isValid;
		},

		async onImportData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let json = JSON.parse(this.loadedTextFromFile);
				let assetsObjResult = await assetsDAO.getAssetsByCode(json.assets);
				this.assetsObj = assetsObjResult[0];

				let param = {
					companiesObj: this.companiesObj,
					storageLocationsObj: this.storageLocationsObj,
					connectionsObj: this.connectionsObj,
					assetTypesObj: this.assetTypesObj,
					transportationsObj: this.transportationsObj,
					assetsObj: this.assetsObj,
				};

				let errors = DispatchUtil.validate(json, param);
				if (errors.length > 0) {
					this.hasError = true;
					this.importErrors = errors;
					this.importResultLog = 'The imported form has error(s).';
					this.importDone = true;
					this.importOngoing = false;

					// hide loading indicator
					this.isLoading = false;
					return;
				}

				this.buildDispatchForm(json);
				await this.saveOnDatabase();

			} catch (_error) {
				this.$toaster.warning('The imported dispatch data is invalid and cannot be processed');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		buildDispatchForm(json) {
			this.dispatch = {};

			this.updatePrimaryDetails(this.dispatch, json);
			this.updateAssetRelatedFields(this.dispatch, json);
			this.updateBooleanStateFields(this.dispatch, json);
			this.updateOtherDispatchFields(this.dispatch, json);
			this.updateTimestamps(this.dispatch, json);
		},

		updatePrimaryDetails(dispatch, json) {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			let displayTimeStamp = DateUtil.getFormattedDateWithTime(currTimeStamp);

			dispatch.dispatchId = json.dispatchId
				? json.dispatchId
				: 'DS' + currTimeStamp;

			dispatch.status = json.status;
			dispatch.source = this.getDispatchSource(json);
			dispatch.destination = this.getDispatchDestination(json);
			dispatch.sourceScanners = [];
			dispatch.destinationScanners = [];

			dispatch.notes = json.notes ? json.notes + '; ' : '';
			dispatch.notes += 'Created from Dispatch Importer at ' + displayTimeStamp;

			dispatch.transportation = this.getTransportation(json);
			dispatch.driver = this.getDriver(json);
		},
		getDispatchSource(json) {
			let company = json.source.company;
			let companyObj = this.companiesObj[company];

			let storageLocation = json.source.storageLocation;
			let locObj = this.storageLocationsObj[storageLocation];

			return {
				company: companyObj.name,
				companyId: companyObj.id,
				storageLocation: locObj.name,
				storageLocationId: locObj.id,
				geoaddress: locObj.geoaddress,
			};
		},
		getDispatchDestination(json) {
			let company = json.destination.company;
			let companyObj = this.companiesObj[company];

			let storageLocation = json.destination.storageLocation;
			let locObj = this.storageLocationsObj[storageLocation];

			return {
				company: companyObj.name,
				companyId: companyObj.id,
				storageLocation: locObj.name,
				storageLocationId: locObj.id,
				geoaddress: locObj.geoaddress,
			};
		},
		getTransportation(json) {
			let company = json.transportation.company;
			return {
				plateNo: json.transportation.plateNo,
				company: company,
				companyId: this.companiesObj[company].id
			};
		},
		getDriver(json) {
			return {
				name: json.driver.name,
				licenseUrl: json.driver.licenseUrl,
				assistants: json.driver.assistants,
			}
		},

		updateAssetRelatedFields(dispatch, json) {
			dispatch.damagedAssets = [];
			dispatch.addedExpectedAssets = [];
			dispatch.invalidAssets = [];
			dispatch.assetsToDelete = [];
			dispatch.actualAssetsToDelete = [];
			dispatch.inputAssetLog = config.dispatchInputAssetLogDefaultValue;

			dispatch.assets = json.assets;
			dispatch.inputAssetLog['dispatch'].auto = json.assets;

			if (dispatch.status === 'Received') {
				dispatch.actualAssets = json.assets;
				dispatch.inputAssetLog['receipt'].auto = json.assets;
			}
		},

		updateBooleanStateFields(dispatch, json) {
			dispatch.apply1Up1Down = false;
			dispatch.doneAutoScan = false;
			dispatch.donePushPull = false;
			dispatch.isOffline = false;

			let sourceObj = this.companiesObj[json.source.company];
			let destinationObj = this.companiesObj[json.destination.company];

			dispatch.fromInactiveNode = DispatchUtil.isInactiveCompany(sourceObj);
			dispatch.toInactiveNode = DispatchUtil.isInactiveCompany(destinationObj);
		},

		updateOtherDispatchFields(dispatch, json) {
			dispatch.proofOfReceipt = {};
			dispatch.creationSource = '';
			dispatch.operation = '';
			dispatch.newTransportationToAdd = this.getNewTransportationToAdd(json);
			dispatch.prevDispatchFromPushPull = null;
			dispatch.validationWarnings = [];
		},
		getNewTransportationToAdd(json) {
			let newTransportationToAdd = null;

			// Build Transportation Form if still not existing
			if (!this.transportationsObj[json.transportation.plateNo]) {
				let company = json.transportation.company;
				newTransportationToAdd = {
					plateNo: json.transportation.plateNo,
					description: '',
					company: company,
					companyId: '',
					isActive: 'true',
					createdBy: this.currUserId,
					dateCreated: DateUtil.getCurrentTimestamp(),
					updatedBy: this.currUserId,
					dateUpdated: DateUtil.getCurrentTimestamp(),
				};

				let companyObj = this.companiesObj[company];
				if (companyObj) {
					newTransportationToAdd.companyId = this.companiesObj[company].id;
				}
			}

			return newTransportationToAdd;
		},

		updateTimestamps(dispatch, json) {
			let dateDeployed = DateUtil.getTimestamp(json.dateDeployed);

			dispatch.dateCreated = dateDeployed;
			dispatch.createdBy = this.currUserId;

			dispatch.dateUpdated = dateDeployed;
			dispatch.updatedBy = this.currUserId;

			dispatch.dateDeployed = dateDeployed;
			dispatch.deployedBy = this.currUserId;

			dispatch.dateCancelled = null;
			dispatch.cancelledBy = '';

			if (dispatch.status === 'Received') {
				let dateReceived = DateUtil.getTimestamp(json.dateReceived);
				dispatch.dateReceived = dateReceived;
				dispatch.receivedBy = this.currUserId;
			} else {
				dispatch.dateReceived = null;
				dispatch.receivedBy = '';
			}
		},

		async saveOnDatabase() {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				// Continue with import
				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					dispatch: this.dispatch,
				};

				let { data } = await dataImporterApi.importDispatch(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let dispatchesObj = data.dispatches;
				this.$store.dispatch('updateAllDispatches', dispatchesObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
};
</script>